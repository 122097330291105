// import consumer from "./consumer"
// import { printLabel } from "../print/printActions.js"
//
// let user_id = localStorage.getItem("user_id")
// let token = localStorage.getItem("token")
// let onElectron = localStorage.getItem("electron")
//
// consumer.subscriptions.create({
//   channel: "OrdersToPrintChannel",
//   user_id: user_id,
//   token: token }, {
//     connected() {
//       console.log("Connected to orders channel")
//       // Called when the subscription is ready for use on the server
//     },
//
//     disconnected() {
//       console.log("Disconnected from orders channel")
//       // Called when the subscription has been terminated by the server
//     },
//
//     received(data) {
//       // Called when there's incoming data on the websocket for this channel
//       console.log(data)
//       if (data.status == 1) {
//         getOrders();
//       }
//       // if (onElectron) {
//       //   data.orders.map(o => {
//       //     printLabel(o.shipping_label)
//       //   })
//       // }
//     }
// });
